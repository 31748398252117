/**
 * Toggle mobile nav
 */
(function() {
    var toggleNav = document.getElementById('toggle-nav');

    toggleNav.addEventListener('click', function() {
        var parent = toggleNav.parentElement.querySelector('.nav-mobile');

        if (parent.classList.contains('active')) {
            parent.classList.remove('active');
            toggleNav.classList.remove('active');
        } else {
            parent.classList.add('active');
            toggleNav.classList.add('active');
        }
    });
})();

/**
 * When the user scrolls down on a dekstop, hide the navbar. When the user scrolls up, show the navbar
 */
(function() {
    // var prevScrollpos = window.pageYOffset;
    // var navbar = document.getElementById("navbar");
    // var sticky = navbar.offsetTop + 300;

    // window.onscroll = (function() {
    //     var currentScrollPos = window.pageYOffset;

    //     if (prevScrollpos > currentScrollPos) {
    //         if (window.pageYOffset >= sticky) {
    //             navbar.classList.add("scroll");
    //         } else {
    //             navbar.classList.remove("scroll");
    //         }
    //     } else {
    //         navbar.classList.remove('scroll');
    //     }
    //     prevScrollpos = currentScrollPos;
    // });
})();

/**
 * When the user scrolls down on a mobile, hide the navbar. When the user scrolls up, show the navbar
 */
(function() {
    // var prevScrollpos = window.pageYOffset;
    // var navbar = document.getElementById("mobile-navbar");
    // var sticky = navbar.offsetTop + 60;

    // window.onscroll = (function() {
    //     var currentScrollPos = window.pageYOffset;

    //     if (prevScrollpos > currentScrollPos) {
    //         if (window.pageYOffset >= sticky) {
    //             navbar.classList.add("scroll");
    //         } else {
    //             navbar.classList.remove("scroll");
    //         }
    //     } else {
    //         navbar.classList.remove('scroll');
    //     }
    //     prevScrollpos = currentScrollPos;
    // });
})();

/**
 * Touch hover nav
 */
(function() {

    // get all li nodes with children
    var navItems = document.getElementsByClassName('has-children');

    // loop trough all navs
    for (var i = 0; i < navItems.length; i++) {
        var el = navItems[i].childNodes[0];
        openClose(i, el);
    }

    function openClose(i, el){
        el.addEventListener("touchstart", function(e){
            if( !navItems[i].classList.contains('open') ){
                closeAll();
                e.preventDefault();
                navItems[i].childNodes[1].parentElement.classList.add('open');
            } else {
                navItems[i].childNodes[1].parentElement.classList.remove('open');
            }
        });
    }

    // Close all open child navs if they are present.
    function closeAll(){
        for (var i = 0; i < navItems.length; i++) {
            navItems[i].childNodes[1].parentElement.classList.remove('open');
        }
    }
})();
